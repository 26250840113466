export const baseURL = 'https://dev.jjludo.com/api/'
// export const baseURL = "http://localhost:8000/api/public/";
export const GAMES_LIST = "game_listings";
export const BATTLES = "battles/";
export const LIVE_BATTLES = "live_battles/";
export const SEND_OTP = "send_otp";
export const VERIFY_OTP = "verify_otp";
export const CHECK_AUTH = "check_auth";
export const LOGOUT = "logout";
export const UPLOAD_KYC = "kyc_uploads";
export const UPDATE_REFER_CODE = "update_referral";
export const UPDATE_USER_PROFILE = "update_userProfile";
export const CREATE_ORDER = "create_order";
export const CONFIRM_ORDER = "check_payment_status/";
export const CREATE_ROOM = "create_room";
export const GAME_RESULT = "game_result";
export const GET_DOCUMENTS = "get_documents";
export const GET_STATES = "get_states";
export const WITHDRAW_FUNDS = "withdraw_funds";
export const MANUAL_WITHDRAW_FUNDS = "manual_withdraw_funds";
export const GET_TOTAL_REFERS = "total_referral";
export const ADD_ROOM_CODE = "add_room_code";
export const GAME_HISTORY = "battle_transaction_history";
export const REFER_HISTORY = "refer_transaction_history";
export const TRANSACTION_HISTORY = "wallet_transaction_history";
export const REDEEM_REFER_FUNDS = "withdraw_referral_amount";
export const ADD_GAME_BATTLE = "add_game_battle";
export const DELETE_GAME_BATTLE = "delete_game_battle";
export const REJECT_GAME_BATTLE = "reject_game_battle";
export const JOINED_USER_DETAIL = "joined_user_detail";
export const GET_ROOM_CODE = "get_room_code/";

import Header from "./common/Header";
import RightContainer from "./common/RightContainer";
import SideNav from "./common/SideNav";
import Loading from "../HOC/Loading";
import { useEffect, useState } from "react";
import { checkOrderStatus } from "../store/addFund";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

function PaymentStatus() {
    const { paymentId } = useParams();
    const dispatch = useDispatch();
    const [paymentData, setPaymentData] = useState({});
    useEffect(() => {
        getPaymentResponse();
    }, []);
    const getPaymentResponse = () => {
        dispatch(
            checkOrderStatus(paymentId, (dispatch, res) => {
                setPaymentData(res);
            })
        );
    };
    return (
        <>
            <SideNav />
            <div className="leftContainer">
                <Header />
                <div className="main-area" style={{ paddingTop: "60px" }}>
                    <div
                        className="cxy flex-column mx-4"
                        style={{ marginTop: "70px" }}
                    >
                        <div className="payment-status">
                            <span
                                className={`icon-wrap + ${
                                    paymentData.success ? "success" : "failed"
                                }`}
                            >
                                <img
                                    src={paymentData.success ?  `${process.env.PUBLIC_URL}/img/check.svg` : `${process.env.PUBLIC_URL}/img/cross.png`}
                                    alt=""
                                />
                            </span>
                            <h5>Status: {paymentData?.data?.state ? paymentData?.data?.state : 'Failed' }</h5>
                        </div>
                        <table className="table table-bordered">
                            <tr>
                                <td>
                                    <strong>Amount:</strong>
                                </td>
                                <td>{paymentData?.data?.amount}</td>
                            </tr>
                            {/* <tr>
                                <td>
                                    <strong>Transaction Id:</strong>
                                </td>
                                <td>
                                    {paymentData?.data?.merchantTransactionId}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Payment Type:</strong>
                                </td>
                                <td>
                                    {paymentData?.data?.paymentInstrument?.type}
                                </td>
                            </tr> */}
                        </table>
                    </div>
                </div>
            </div>
            <RightContainer />
        </>
    );
}

export default Loading(PaymentStatus);
